import { css } from "@emotion/core";
import { mq } from "../../../cssInJs";

export default {
  pageTiles: css(
    mq({
      fontFamily: "'proxima-nova', sans-serif",
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      margin: ["0", "2rem -10px"],
      overflow: "hidden",
    })
  ),
  pageTile__tile: css(
    mq({
      flex: "0 0 100%",
      maxWidth: "100%",
      padding: "10px",
      paddingBottom: ["10px", "3rem"],
      "&:nth-of-type(even)": {
        ".pageTile__bsyc__bg": mq({
          order: ["0", null, "2"],
        }),
        ".pageTile__bsyc__content__details": mq({
          order: "1",
        }),
      },
    })
  ),
  tile__content: css({
    "&:hover": {
      textDecoration: "none",
      "& .pageTile__bsyc__bg2": {
        transform: "scale(1.05)",
      },
      "& .pageTile__bsyc__btn": {
        color: "#202123",
      },
    },
  }),
  tile__flip__wrapper: css({
    zIndex: "-1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    position: "relative",
    flexWrap: "wrap",
  }),
  tile__bg: css(
    mq({
      height: "0",
      paddingTop: ["50%", "35%", "30%"],
      flex: "1 1 300px",
      overflow: "hidden",
      position: "relative",
    })
  ),
  tile__bg2: css(
    mq({
      height: "100%",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      transition: "all .2s",
      position: "absolute",
      top: "0px",
      width: "100%",
    })
  ),
  tile__details: css({
    overflow: "hidden",
    flexGrow: "1",
    width: "100%",
    bottom: "0",
    flex: "1 1 300px",
    transition: "all .2s ease-in-out",
    color: "#a8a8a8y",
    position: "relative",
    "& .pageTile__bsyc__container": mq({
      position: ["static,", null, "absolute"],
      top: "50%",
      left: "50%",
      transform: ["none", null, "translate(-50%, -50%)"],
      width: ["100%", null, "75%"],
      padding: ["1rem", null, "0px"],
      "& .pageTile__bsyc__title": {
        fontFamily: "'trajan-pro-3', serif",
        fontSize: "2rem",
        lineHeight: "1",
        margin: "0",
        paddingTop: ".5rem",
        paddingBottom: ".5rem",
        color: "#a8a8a8",
        textTransform: "uppercase",
        fontWeight: "400",
      },
      "& .pageTile__bsyc__yellow__line": mq({
        width: "2rem",
        height: "2px",
        backgroundColor: "#d96d2b",
        margin: [".2rem 0 1rem", ".5rem 0 1.5rem"],
      }),
      "& .pageTile__bsyc__desc": {
        lineHeight: "1.3",
        fontSize: ".9rem",
        minHeight: "70px",
        color: "#a8a8a8",
      },
      "& .pageTile__bsyc__btn": mq({
        backgroundColor: "transparent",
        color: "#a8a8a8",
        border: "1px solid #a8a8a8",
        borderRadius: "4px",
        display: "inline-block",
        padding: ".6rem 1rem",
        fontSize: "1rem",
        marginTop: ["1.2rem", "2rem"],
        // transition: "all 0.5s ease-in-out",
        position: "relative",
        overflow: "hidden",
        fontWeight: "500",
      }),
    }),
  }),
};
