import React, { useLayoutEffect, useRef } from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
// import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/aboriginal-tours', title: 'ABORIGINAL', title2: 'TOURS', icon: 'bottom-nav-icon.png', iconHover: 'bottom-nav-icon.png' },
    { url: '/events-and-festivals', title: 'EVENTS', title2: 'FESTIVALS', icon: 'bottom-nav-icon.png', iconHover: 'bottom-nav-icon.png' },
    { url: '/food-and-wine', title: 'FOOD', title2: 'WINE', icon: 'bottom-nav-icon.png', iconHover: 'bottom-nav-icon.png' },
    { url: '/nature-and-wildlife', title: 'NATURE', title2: 'WILDLIFE', icon: 'bottom-nav-icon.png', iconHover: 'bottom-nav-icon.png' },
    { url: '/walking-tours', title: 'WALKING', title2: 'TOURS', icon: 'bottom-nav-icon.png', iconHover: 'bottom-nav-icon.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>
          <span>{link.title}</span>
          <br />
          <span>{link.title2}</span>
        </div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        {/*<div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div>*/}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
    </>
  );
}
